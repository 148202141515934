import { useQuery } from "@tanstack/react-query";
import { getAnthology } from "../function/getAnthology";
import Box from "@mui/material/Box";
import AnthologyFlip from "./AnthologyFlip";
import ContentContainer from "../style/ContentContainer";
import { useNavigate } from "react-router-dom";

const Anthology = (props) => {
  const { englishVersion } = props;
  const { data: anthologyData } = useQuery({
    queryKey: ["anthology", "data"],
    queryFn: getAnthology(),
  });

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/anthology/${id}`);
  };

  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          paddingTop: "4em",
          paddingBottom: "4em",
          paddingLeft: "6em",
          paddingRight: "6em",
          display: "flex",
          flexWrap: "wrap",
          gap: "6em 6em",
          justifyContent: "space-evenly",
        }}
      >
        {anthologyData.data.map((a) => (
          <Box
            key={a.id}
            sx={{ position: "relative", width: "180px", height: "240px" }}
          >
            <AnthologyFlip
              data={a}
              handleClick={handleClick}
              englishVersion={englishVersion}
            />
          </Box>
        ))}
      </Box>
    </ContentContainer>
  );
};

export default Anthology;
