import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getPreface } from "../function/getPreface";
import { getPrefacePunc } from "../function/getPrefacePunc";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

const Preface = (props) => {
  const { id } = props;

  const [showPunctuation, setShowPunctuation] = useState(false);
  const [punctuationText, setPunctuationText] = useState("");

  const { data: prefaceData } = useQuery({
    queryKey: ["preface", id],
    queryFn: getPreface(id),
  });

  // const { data: prefaceDataPunc, refetch } = useQuery({
  //   queryKey: ["preface", "punc", id],
  //   queryFn: getPrefacePunc(id),
  //   enabled: false,
  // });

  const creationYearList = prefaceData.data.creation_year
    ? prefaceData.data.creation_year.split(";")
    : prefaceData.data.creation_year;

  const creationYearDynastyList = prefaceData.data.creation_year_dynasty
    ? prefaceData.data.creation_year_dynasty.split(";")
    : prefaceData.data.creation_year_dynasty;

  const content = prefaceData.data.content_format
    .split(";")
    .map((p) => p.split("##"));

  const handleClick = () => {
    // refetch();
    if (punctuationText === "") {
      getPrefacePunc(id).then((d) => setPunctuationText(d.data.content));
    }
    setShowPunctuation((prev) => !prev);
  };

  return (
    <Box sx={{ paddingTop: "1em" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "1em",
        }}
      >
        <Typography variant="h5">{`序言${id.slice(-1)}`}</Typography>
        <Typography variant="h5">{prefaceData.data.author}</Typography>
      </Box>
      <Box>
        <Button
          variant={
            showPunctuation && punctuationText !== "" ? "contained" : "outlined"
          }
          onClick={handleClick}
          key="Punctuation"
        >
          {showPunctuation && punctuationText === "" ? (
            <CircularProgress size={20} />
          ) : showPunctuation ? (
            "Show Original Texts"
          ) : (
            "Add Punctuation via ChatGPT"
          )}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          textAlign: "start",
          paddingTop: "1em",
          // columnGap: "1em",
        }}
      >
        <Box>
          {prefaceData.data.creation_year && (
            <Typography>
              {creationYearList
                .map(
                  (y, i) =>
                    `${y}${
                      creationYearDynastyList
                        ? `（${creationYearDynastyList[i]}）`
                        : ""
                    }`
                )
                .join("至")}
            </Typography>
          )}
          {showPunctuation && punctuationText !== "" ? (
            <Typography>{punctuationText}</Typography>
          ) : (
            content.map((t, i) => <Typography key={i}>{t[1]}</Typography>)
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Preface;
