import Box from "@mui/material/Box";
import BackgroundPic from "../image/backgroundpic.jpg";
import { Typography } from "@mui/material";
import { getAnthologyKey } from "../function/getAnthologyKey";
import { getPoemCountTotal } from "../function/getPoemCountTotal";
import { useQuery } from "@tanstack/react-query";
import "../css/PoemCount.css";
import { useEffect } from "react";

const Home = () => {
  const { data: anthologyKey } = useQuery({
    queryKey: ["anthology", "key"],
    queryFn: getAnthologyKey(),
  });

  const { data: poemCountTotal } = useQuery({
    queryKey: ["poem", "count", "total"],
    queryFn: getPoemCountTotal(),
  });

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    const poemCounter = document.querySelector("div.poem-count-animation");
    const anthologyCounter = document.querySelector(
      "div.anthology-count-animation"
    );

    let keyframes = `@keyframes poem-counter {
     from {
    --num: 0;
      }
      to {
        --num: ${poemCountTotal.data};
      }
    }
      
    @keyframes anthology-counter {
     from {
    --num: 0;
      }
      to {
        --num: ${Object.keys(anthologyKey).length};
      }
    }`;

    let style = document.createElement("STYLE");
    style.innerHTML = keyframes;
    head.appendChild(style);

    poemCounter.style.animationName = "poem-counter";
    anthologyCounter.style.animationName = "anthology-counter";
  }, [anthologyKey, poemCountTotal]);

  return (
    <Box
      sx={{
        height: "100%",
        backgroundImage: `url(${BackgroundPic})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: "1.5em", md: "3em" },
            fontWeight: "600",
          }}
        >
          明清古詩總集數據庫
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: "1.5em", md: "3em" },
            fontWeight: "600",
          }}
        >
          A Database of Late Imperial Anthologies of Pre-Tang Poems
        </Typography>
        <Box
          sx={{
            paddingTop: "5em",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            columnGap: 1,
            fontSize: { xs: "0.75em", md: "2em" },
          }}
        >
          <div className="anthology-count-animation" />
          <div>Anthologies</div>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            columnGap: 1,
            fontSize: { xs: "0.75em", md: "2em" },
          }}
        >
          <div className="poem-count-animation" />
          <div>Poems</div>
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "0%",
          left: "50%",
          width: "100%",
          fontSize: { xs: "0.75em", md: "1em" },
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography>
          This website is built to provide research tools for studying Chinese
          poetry anthologies and early medieval Chinese poetry across the world.
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
