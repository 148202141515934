import { useQuery } from "@tanstack/react-query";
import { getAnthologySimilarity } from "../function/getAnthologySimilarity";
import { getAnthologyKey } from "../function/getAnthologyKey";
import { getPoemCount } from "../function/getPoemCount";
import AnthologyCorrelogram from "./AnthologyCorrelogram";
import Box from "@mui/material/Box";
import ContentContainer from "../style/ContentContainer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";

const AnthologySimilarity = () => {
  const divRef = useRef();

  const { data: anthologyKey } = useQuery({
    queryKey: ["anthology", "key"],
    queryFn: getAnthologyKey(),
  });

  const { data: similarityData } = useQuery({
    queryKey: ["anthology", "similarity"],
    queryFn: getAnthologySimilarity(Object.keys(anthologyKey), "pair"),
  });

  const { data: poemCount } = useQuery({
    queryKey: ["poem", "count"],
    queryFn: getPoemCount(Object.keys(anthologyKey)),
  });

  const [checkedAnthology, setCheckedAnthology] = useState(
    Object.keys(anthologyKey).reduce(
      (a, b, i) => ({ ...a, [b]: i < 10 ? true : false }),
      {}
    )
  );

  const handleChecked = (id) => {
    setCheckedAnthology((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const [dimension, setDimension] = useState({ width: null, height: null });

  useEffect(() => {
    if (divRef.current) {
      setDimension({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        rowGap: "1em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: { xs: "40%", md: "100%" },
          width: { xs: "100%", md: "25%" },
        }}
      >
        <FormControl sx={{ overflow: "auto" }}>
          {Object.keys(anthologyKey).map((a) => (
            <FormControlLabel
              checked={checkedAnthology[a]}
              control={<Checkbox />}
              label={anthologyKey[a]}
              key={a}
              onChange={() => handleChecked(a)}
            />
          ))}
        </FormControl>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: { xs: "100%", md: "75%" },
          overflow: "auto",
        }}
        ref={divRef}
      >
        <AnthologyCorrelogram
          anthlogyKey={anthologyKey}
          totalData={poemCount.data}
          similarityData={similarityData.data}
          dataFilter={checkedAnthology}
          dimension={dimension}
        />
      </Box>
    </ContentContainer>
  );
};

export default AnthologySimilarity;
