import { Suspense, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "./App.css";
import ErrorBoundary from "./component/ErrorBoundary";
import {
  Route,
  Routes,
  // useSearchParams,
  // useLocation,
  // useNavigate,
} from "react-router-dom";
import Header from "./component/Header";
import AnthologySimilarity from "./component/AnthologySimilarity";
import Home from "./component/Home";
import Analytics from "./component/Analytics";
import AuthorRelationship from "./component/AuthorRelationship";
import Search from "./component/Search";
import Anthology from "./component/Anthology";
import AnthologyDetail from "./component/AnthologyDetail";
import About from "./component/About";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import Guide from "./component/Guide";
import ReactGA from "react-ga4";
import AnthologyData from "./component/AnthologyData";

function App() {
  // const [searchParams] = useSearchParams();
  // const navigate = useNavigate();

  // console.log(searchParams.get("lang"));
  const [englishVersion, setEnglishVersion] = useState(true);

  const handleLanguage = () => {
    setEnglishVersion((prev) => !prev);
    // navigate("?lang=en");
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }, []);

  return (
    <div className="App">
      <div className="App-Container">
        <ErrorBoundary fallback={<div>Error</div>}>
          <Header
            englishVersion={englishVersion}
            handleLanguage={handleLanguage}
          />
          <Suspense
            fallback={
              <Box sx={{ paddingTop: "2em" }}>
                <CircularProgress />
              </Box>
            }
          >
            <Box
              sx={{
                height: "calc(100vh - 64px - 64px)",
              }}
            >
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="/search"
                  element={<Search englishVersion={englishVersion} />}
                />
                <Route
                  exact
                  path="/anthology"
                  element={<Anthology englishVersion={englishVersion} />}
                />
                <Route
                  exact
                  path="/anthology/:id"
                  element={<AnthologyDetail englishVersion={englishVersion} />}
                />
                {/* <Route exact path="/preface/:prefaceId" element={<Preface />} /> */}
                <Route
                  exact
                  path="/analytics"
                  element={<Analytics englishVersion={englishVersion} />}
                />
                <Route
                  exact
                  path="/analytics/similarity"
                  element={<AnthologySimilarity />}
                />
                <Route
                  exact
                  path="/analytics/author_relationship"
                  element={<AuthorRelationship />}
                />
                <Route
                  exact
                  path="/analytics/data_of_anthologies"
                  element={<AnthologyData />}
                />
                <Route
                  exact
                  path="/about"
                  element={<About englishVersion={englishVersion} />}
                />
                <Route
                  exact
                  path="/contact"
                  element={<Contact englishVersion={englishVersion} />}
                />
                <Route
                  exact
                  path="/guide"
                  element={<Guide englishVersion={englishVersion} />}
                />
              </Routes>
            </Box>
            <Footer englishVersion={englishVersion} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
