export function getPoem(keyword, field) {
  return () =>
    fetch(
      `${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_URL}:${
        process.env.REACT_APP_API_PORT
      }/api/v1/poem/record/keyword/${encodeURIComponent(
        keyword
      )}/field/${field}`
    ).then((res) => res.json());
}
