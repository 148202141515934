import ContentContainer from "../style/ContentContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Contact = () => {
  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        textAlign: "justify",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography>
          Thank you very much for visiting our website. Our database and website
          are still under development. Please check the links below to submit
          your feedback.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">General Inquiry</Typography>
        <Box display="flex" alignItems="center" sx={{ paddingTop: "1em" }}>
          <Typography>
            If you have any questions, suggestions, and comments, feel free to{" "}
            <Link href="mailto:jing-jc.chen@polyu.edu.hk" target="_blank">
              email us
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Bug Report</Typography>
        <Box display="flex" alignItems="center" sx={{ paddingTop: "1em" }}>
          <Typography>
            Please click{" "}
            <Link
              href="https://ko85g04rg25.typeform.com/to/LaFRGQMn"
              target="_blank"
            >
              bug report
            </Link>{" "}
            to report bugs you find on the website.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Feature Request</Typography>
        <Box display="flex" alignItems="center" sx={{ paddingTop: "1em" }}>
          <Typography>
            Please click{" "}
            <Link
              href="https://ko85g04rg25.typeform.com/to/dPlugXMB"
              target="_blank"
            >
              feature request
            </Link>{" "}
            to let us know your ideas and suggestions for our future
            development.
          </Typography>
        </Box>
      </Box>
    </ContentContainer>
  );
};

export default Contact;
