import ContentContainer from "../style/ContentContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import XXPic from "../image/XiaotongXu.jpg";
import JWPic from "../image/JoyceWong.jpg";
import YFPic from "../image/YanzheFeng.jpg";
import TYPic from "../image/TimothyYeung.jpg";

const About = () => {
  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        textAlign: "justify",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography variant="h6">About the Project</Typography>
        <Typography>
          This website is built to provide an online platform and digital
          research tools for conducting research of Chinese poetry anthologies.
          It is an online database of anthologies selecting pre-Tang poems
          produced in late imperial China (1368-1911). It also provides several
          research tools for studying Ming-Qing poetry anthologies and early
          medieval Chinese poetry.
        </Typography>
        <Typography sx={{ paddingTop: "0.5em" }}>
          This project is generously sponsored by a Start-up Fund for New
          Recruits provided by{" "}
          <Link href="https://www.polyu.edu.hk" target="_blank">
            The Hong Kong Polytechnic University
          </Link>{" "}
          (Summer 2021-Summer 2024), and an Early Career Scheme (ECS) project
          grant (January 2022-December 2024) from the{" "}
          <Link
            href="https://www.ugc.edu.hk/eng/rgc/funding_opport/ecs"
            target="_blank"
          >
            Research Grants Council
          </Link>{" "}
          (RGC) in Hong Kong.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">About the Data</Typography>
        <Typography>
          As of June 2024, this database contains 58 anthologies and more than
          10850 records of poem titles selected in these books.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Progress</Typography>
        <Stepper activeStep={3} orientation="vertical">
          <Step expanded>
            <StepLabel>January 2022</StepLabel>
            <StepContent>
              <Typography>The project launched</Typography>
            </StepContent>
          </Step>
          <Step expanded>
            <StepLabel>November 2022</StepLabel>
            <StepContent>
              <Typography>
                Website powered by a back-end database launched.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded>
            <StepLabel>February 2023</StepLabel>
            <StepContent>
              <Typography>
                23 anthologies are available in the database. Users can conduct
                full-text search by poem title or poem author. Users can browse
                the anthology metadata information such as edition and
                publication date. Users can click each book to browse the
                prefaces.
              </Typography>
            </StepContent>
          </Step>

          <Step expanded>
            <StepLabel>June 2024</StepLabel>
            <StepContent>
              <Typography>
                58 anthologies are available in the database. Users can conduct
                full-text search by poem dynasty. Users can filter results by
                anthology titles. Two digital research tools are available on
                the{" "}
                <Link
                  href="https://chinesepoetryanthology.org/analytics"
                  target="_blank"
                >
                  “Analytics”
                </Link>{" "}
                page.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded>
            <StepLabel>Upcoming</StepLabel>
            <StepContent>
              <Typography>
                More data to be included. More research tools are under
                development.
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Research Team</Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "2em",
            rowGap: "2em",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="280"
              image="/static/images/cards/contemplative-reptile.jpg"
              alt="Jing Chen"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Dr. Jing Chen
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Principal Investigator and Assistant Professor, The Hong Kong
                Polytechnic University
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Link href="https://jingchenjc.com" target="_blank">
                  https://jingchenjc.com
                </Link>
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="280"
              image={JWPic}
              alt="Joyce Wong"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Joyce Wong
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Joyce Wong graduated from the Hong Kong Polytechnic University
                with a Bachelor’s degree in Bilingual Interdisciplinary Chinese
                Studies. In her spare time, she always watches movies as it is
                an excellent way to reduce stress and bring happiness. She also
                loves listening to music and traveling like an adventurer.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="280"
              image={TYPic}
              alt=" Timothy Yeung"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Timothy Yeung
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Timothy Yeung is experienced in supporting digital scholarship
                development, particularly on data curation and data
                visualization. He was a project administrator serving at the
                Digital and Multimedia Services Section of the Hong Kong Baptist
                University Library.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="280"
              image={XXPic}
              alt="Xiaotong Xu"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Xiaotong Xu
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Xiaotong Xu has been conducting academic research on classical
                Chinese literary criticism, Ming-Qing poetics, and Du Fu poetry.
                She graduated from the University of Hong Kong with a Master's
                degree in Chinese Literature, currently a MPhil student at
                Lingnan University in Hong Kong. Besides doing research,
                Xiaotong loves hiking with her cute naughty Shiba inu, has been
                playing more than 30 video games. she also loves to produce
                travel vlogs, and to write restaurants and movies reviews.
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="280"
              image={YFPic}
              alt="Yanzhe Feng"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Yanzhe Feng
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Yanzhe Feng was a senior at Dougherty Valley High School in San
                Ramon, California in the US when working on this project. Yanzhe
                is currently a freshman majoring in computer science at UCLA. In
                his spare time, he enjoys coding, playing games, and hanging out
                with friends.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Copyright Information</Typography>
        <Typography>
          The research tem retains the copyright of "A Database of Late Imperial
          Anthologies of Pre-Tang Poems" website and the datasets that are
          published on that.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Disclaimer</Typography>
        <Typography>
          The information provided on this website is for knowledge sharing.
          While the research team endeavours to ensure the accuracy of all the
          data and information, no statement, representation, warranty or
          guarantee, express or implied, is given as to its accuracy or
          appropriateness for use in any particular circumstances. Users are
          responsible for making their own assessment of all information on this
          website and are advised to verify such information or obtaining
          independent advice before acting upon it.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "2em",
        }}
      >
        <Typography variant="h6">Terms of Use</Typography>
        <Typography>
          The information provided on this website is for knowledge sharing.
          While the research team endeavours to ensure the accuracy of all the
          data and information, no statement, representation, warranty or
          guarantee, express or implied, is given as to its accuracy or
          appropriateness for use in any particular circumstances. Users are
          responsible for making their own assessment of all information on this
          website and are advised to verify such information or obtaining
          independent advice before acting upon it.
        </Typography>
        <Typography sx={{ paddingTop: "0.5em" }}>Citation Example:</Typography>
        <Typography sx={{ paddingTop: "0.5em" }}>
          "A Database of Late Imperial Anthologies of Pre-Tang Poems." Accessed
          May 31, 2024. https://chinesepoetryanthology.org.
        </Typography>
      </Box>
    </ContentContainer>
  );
};

export default About;
