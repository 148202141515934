import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadCSV } from "../function/downloadData";

const PoemDetail = (props) => {
  const { onClose, open, data, field, anthologyKey, englishVersion } = props;

  const title = data.title !== null ? data.title.split(";") : [""];
  const titleSource =
    data.title_source !== null ? data.title_source.split(";") : [""];
  const author = data.author !== null ? data.author.split(";") : [""];
  const authorSource =
    data.author_source !== null ? data.author_source.split(";") : [""];
  const dynasty = data.dynasty !== null ? data.dynasty.split(";") : [""];
  const dynastySource =
    data.dynasty_source !== null ? data.dynasty_source.split(";") : [""];
  const style = data.style !== null ? data.style.split(";") : [""];
  const styleSource =
    data.style_source !== null ? data.style_source.split(";") : [""];

  return (
    <Dialog fullWidth={true} onClose={onClose} open={open}>
      <Box sx={{ display: "flex", flexDirection: "column", padding: "2em" }}>
        {(field === "title" || field === "download") && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              {englishVersion ? "Title" : "詩題"}
            </Typography>
            {title.map((t, i) =>
              titleSource[i].split("-").map((s) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={s}
                >
                  <Typography
                    sx={{
                      maxWidth: "80%",
                    }}
                  >
                    {t}
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "50%",
                    }}
                  >
                    {anthologyKey[s]}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        )}
        {(field === "author" || field === "download") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "1em",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              {englishVersion ? "Author" : "作者"}
            </Typography>
            {author.map((a, i) =>
              authorSource[i].split("-").map((s) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={s}
                >
                  <Typography
                    sx={{
                      maxWidth: "80%",
                    }}
                  >
                    {a}
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "50%",
                    }}
                  >
                    {anthologyKey[s]}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        )}
        {(field === "dynasty" || field === "download") && (
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingTop: "1em" }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              {englishVersion ? "Creation Year" : "成詩年代"}
            </Typography>
            {dynasty.map((d, i) =>
              dynastySource[i].split("-").map((s) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={s}
                >
                  <Typography
                    sx={{
                      maxWidth: "80%",
                    }}
                  >
                    {d}
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "50%",
                    }}
                  >
                    {anthologyKey[s]}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        )}
        {field === "download" && (
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingTop: "1em" }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              {englishVersion ? "Style" : "詩體"}
            </Typography>
            {style.map((st, i) =>
              styleSource[i].split("-").map((s) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={s}
                >
                  <Typography
                    sx={{
                      maxWidth: "80%",
                    }}
                  >
                    {st}
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "50%",
                    }}
                  >
                    {anthologyKey[s]}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        )}
        {field === "download" && (
          <Box sx={{ paddingTop: "1em" }}>
            <IconButton onClick={() => downloadCSV(data, anthologyKey)}>
              <DownloadIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default PoemDetail;
