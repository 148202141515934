export function getAnthologyKey() {
  return () =>
    fetch(
      `${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/anthology/record?key=true`
    )
      .then((res) => res.json())
      .then((data) =>
        data.data.reduce((a, b) => ({ ...a, [b.id]: b.title }), {})
      );
}
