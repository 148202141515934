import * as d3 from "d3";
import { useEffect, useRef } from "react";

const PoemCountBar = (props) => {
  const svgRef = useRef();
  const { anthology, data, dimension } = props;

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 0, bottom: 120, left: 0 };
    const width = dimension.width - margin.left - margin.right;
    const height = dimension.height - margin.top - margin.bottom;

    const chartSvg = svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(Object.keys(anthology))
      .padding(0.2);

    chartSvg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-12.5,8)rotate(-90)")
      .style("text-anchor", "end");

    const y = d3
      .scaleLinear()
      .domain([0, Math.max(...Object.values(data.data)) * 1.1])
      .range([height, 0]);

    chartSvg
      .selectAll("mybar")
      .data(Object.keys(anthology))
      .join("rect")
      .attr("x", (d) => x(d))
      .attr("y", (d) => y(data.data[d]))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(data.data[d]))
      .attr("fill", "#69b3a2");
  }, [props, anthology, data, dimension]);

  return (
    <>
      <svg ref={svgRef} />
    </>
  );
};

export default PoemCountBar;
