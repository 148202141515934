import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

const PoemList = (props) => {
  const { data, handleClick } = props;

  const title = data.title.split(";");
  const author = data.author.split(";");
  const dynasty = data.dynasty.split(";");

  return (
    <TableRow
      key={data.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <Box
          onClick={() => handleClick(data, "title")}
          sx={{
            display: "inline-flex",
            boxShadow:
              title.length > 1
                ? "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                : "initial",
            padding: "0.5em",
            cursor: "pointer",
          }}
        >
          <Typography>{title[0]}</Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box
          onClick={() => handleClick(data, "author")}
          sx={{
            display: "inline-flex",
            boxShadow:
              author.length > 1
                ? "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                : "initial",
            padding: "0.5em",
            cursor: "pointer",
          }}
        >
          <Typography>{author[0]}</Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box
          onClick={() => handleClick(data, "dynasty")}
          sx={{
            display: "inline-flex",
            boxShadow:
              dynasty.length > 1
                ? "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                : "initial",
            padding: "0.5em",
            cursor: "pointer",
          }}
        >
          <Typography>{dynasty[0]}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleClick(data, "download")}>
          {/* onClick={() => downloadCSV(data, anthologyKey)} */}
          <DownloadIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default PoemList;
