import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const ContentContainer = styled(Box)({
  height: "100%",
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "20px",
  backgroundColor: "#dcd1b5",
});

export default ContentContainer;
