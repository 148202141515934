export function downloadCSV(data, anthologyKey) {
  const downloadBlob = (content, filename, contentType) => {
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  };
  const title = data.title ? data.title.split(";") : [""];
  const titleSource = data.title_source ? data.title_source.split(";") : [""];
  const author = data.author ? data.author.split(";") : [""];
  const authorSource = data.author_source
    ? data.author_source.split(";")
    : [""];
  const dynasty = data.dynasty ? data.dynasty.split(";") : [""];
  const dynastySource = data.dynasty_source
    ? data.dynasty_source.split(";")
    : [""];
  const style = data.style ? data.style.split(";") : [""];
  const styleSource = data.style_source ? data.style_source.split(";") : [""];

  const anthology = titleSource.reduce((a, b) => a.concat(b.split("-")), []);

  const header = ["poem_title", "anthology", "author", "dynasty", "poem_style"];

  const return_data = anthology.map((a) => [
    title[titleSource.findIndex((ts) => ts.includes(a))],
    anthologyKey[a],
    author[authorSource.findIndex((ts) => ts.includes(a))],
    dynasty[dynastySource.findIndex((ts) => ts.includes(a))],
    style[styleSource.findIndex((ts) => ts.includes(a))],
  ]);

  downloadBlob(
    `"\ufeff${header.join('","')}"\r\n${return_data
      .map((d) => `"${d.join('","')}"`)
      .join("\r\n")}`,
    "export.csv",
    "text/csv;charset=utf-8;"
  );
}
