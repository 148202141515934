import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import ContentContainer from "../style/ContentContainer";
import { CardActionArea, Typography } from "@mui/material";
import getImage from "../function/getImage";

const Analytics = (props) => {
  const { englishVersion } = props;

  const tools = [
    {
      name: { english: "similarity", chinese: "總選之間的關係" },
      brief: {
        english: "This is briefing introduction of the tool 1",
        chinese: "總選之間的關係",
      },
      img: "similaritytool.png",
    },
    {
      name: { english: "author relationship", chinese: "作者與總選的關係" },
      brief: {
        english: "This is briefing introduction of the tool 2",
        chinese: "作者與總選的關係",
      },
      img: "authorrelationshiptool.png",
    },
    ,
    {
      name: { english: "data of anthologies", chinese: "總選的數據" },
      brief: {
        english: "This is briefing introduction of the tool 3",
        chinese: "總選的數據",
      },
      img: "authorrelationshiptool.png",
    },
  ];

  const navigate = useNavigate();

  const handleClick = (tool) => {
    navigate(`/analytics/${tool.replaceAll(" ", "_")}`);
  };

  return (
    <ContentContainer>
      <Stack spacing={2}>
        {tools.map((t) => (
          <CardActionArea key={t.name.english}>
            <Card
              sx={{ display: "flex", padding: "1em" }}
              onClick={() => handleClick(t.name.english)}
            >
              <Box sx={{ float: "left", textAlign: "left" }}>
                <CardContent>
                  <Typography sx={{ fontWeight: "600" }}>
                    {englishVersion
                      ? t.name.english.toUpperCase()
                      : t.name.chinese}
                  </Typography>
                  <Typography>
                    {englishVersion ? t.brief.english : t.brief.chinese}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component="img"
                sx={{ width: 151, marginLeft: "auto", marginRight: "0" }}
                src={getImage(t.img)}
                alt={t.name.english}
              ></CardMedia>
            </Card>
          </CardActionArea>
        ))}
      </Stack>
    </ContentContainer>
  );
};

export default Analytics;
