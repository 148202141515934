import Box from "@mui/material/Box";
import ContentContainer from "../style/ContentContainer";
import Button from "@mui/material/Button";
import { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { useQuery } from "@tanstack/react-query";
import { getPoem } from "../function/getPoem";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import PoemList from "./PoemList";
import ButtonGroup from "@mui/material/ButtonGroup";
import PoemDetail from "./PoemDetail";
import { getAnthologyKey } from "../function/getAnthologyKey";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { getDynasty } from "../function/getDynasty";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Search = (props) => {
  const { englishVersion } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const randomSearch = ["陶淵明", "謝靈運", "古"];
  const randomSearchIdx = Math.floor(Math.random() * randomSearch.length);

  const possibleField = [
    { english: "title", chinese: "詩題" },
    { english: "author", chinese: "作者" },
  ];
  const [searchText, setSearchText] = useState(randomSearch[randomSearchIdx]);
  const [searchField, setSearchField] = useState(["title", "author"]);
  const [selectedField, setSelectedField] = useState(["title", "author"]);
  const [searchTextError, setSearchTextError] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anthologyAnchorEl, setAnthologyAnchorEl] = useState(null);
  const [dynastyAnchorEl, setDynastyAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [zoomInData, setZoomInData] = useState({
    title: "",
    title_source: "",
    author: "",
    author_source: "",
    dynasty: "",
    dynasty_source: "",
    style: "",
    style_source: "",
  });
  const [zoomInField, setZoomInField] = useState("");
  const textValue = useRef();
  const anthologyFilterOpen = Boolean(anthologyAnchorEl);
  const dynastyFilterOpen = Boolean(dynastyAnchorEl);

  const { data: poemData } = useQuery({
    queryKey: ["poem", "list", searchText, searchField],
    queryFn: getPoem(searchText, searchField.join("_")),
  });

  const { data: anthologyKey } = useQuery({
    queryKey: ["anthology", "key"],
    queryFn: getAnthologyKey(),
  });

  const { data: dynastyList } = useQuery({
    queryKey: ["dynasty"],
    queryFn: getDynasty(),
  });

  const handleSelect = (field) => {
    const pos = selectedField.indexOf(field);

    if (selectedField.length === 1 && pos > -1) {
      return;
    }

    pos > -1
      ? setSelectedField((prev) => prev.filter((f) => f !== field))
      : setSelectedField((prev) => [...prev, field]);
  };

  const handleSearch = () => {
    const text = textValue.current.value.replaceAll(" ", "");
    if (text === "") {
      setSearchTextError(true);
      return;
    }

    if (selectedField.length === 0) {
      return;
    }
    setSearchTextError(false);
    setSearchText(text);
    setSearchField(selectedField);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialogOpen = (poem, field) => {
    setZoomInData(poem);
    setZoomInField(field);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAnthologyFilterOpen = (event) => {
    setAnthologyAnchorEl(event.currentTarget);
  };

  const handleAnthologyFilterClose = () => {
    setAnthologyAnchorEl(null);
  };

  const [filterAnthology, setFilterAnthology] = useState(
    Object.keys(anthologyKey).reduce((a, b, i) => ({ ...a, [b]: true }), {})
  );

  const handleAnthologyFilter = (id) => {
    if (id === "all") {
      if (Object.values(filterAnthology).every((v) => v === true)) {
        setFilterAnthology((prev) =>
          Object.keys(prev).reduce((a, b) => ({ ...a, [b]: false }), {})
        );
      } else {
        setFilterAnthology((prev) =>
          Object.keys(prev).reduce((a, b) => ({ ...a, [b]: true }), {})
        );
      }
    } else {
      setFilterAnthology((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const handleDynastyFilterOpen = (event) => {
    setDynastyAnchorEl(event.currentTarget);
  };

  const handleDynastyFilterClose = () => {
    setDynastyAnchorEl(null);
  };

  const [filterDynasty, setFilterDynasty] = useState(
    dynastyList.data.reduce((a, b, i) => ({ ...a, [b]: true }), {})
  );

  const handleDynastyFilter = (id) => {
    if (id === "all") {
      if (Object.values(filterDynasty).every((v) => v === true)) {
        setFilterDynasty((prev) =>
          Object.keys(prev).reduce((a, b) => ({ ...a, [b]: false }), {})
        );
      } else {
        setFilterDynasty((prev) =>
          Object.keys(prev).reduce((a, b) => ({ ...a, [b]: true }), {})
        );
      }
    } else {
      setFilterDynasty((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const filterPoem = poemData.data
    .filter((p) =>
      Object.entries(filterAnthology).reduce((a, b) => {
        if (a) {
          return true;
        }

        if (b[1]) {
          if (p.title_source.includes(b[0])) {
            return true;
          }
        }
        return false;
      }, false)
    )
    .filter((p) =>
      Object.entries(filterDynasty).reduce((a, b) => {
        if (a) {
          return true;
        }

        if (b[1]) {
          if (p.dynasty.includes(b[0])) {
            return true;
          }
        }
        return false;
      }, false)
    );

  return (
    <ContentContainer sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          rowGap: "0.5em",
        }}
      >
        <TextField
          x={{ m: 1, width: 300 }}
          id="keyword"
          label={englishVersion ? "Keyword" : "關鍵詞"}
          variant="standard"
          defaultValue={searchText}
          required
          error={searchTextError}
          sx={{ paddingRight: { xs: "0", md: "1em" } }}
          inputRef={textValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {englishVersion ? "in" : "在"}
              </InputAdornment>
            ),
          }}
        ></TextField>
        <Box
          display={{ xs: "block", md: "inline-flex" }}
          sx={{ paddingRight: { xs: "0", md: "1em" }, verticalAlign: "bottom" }}
        >
          <ButtonGroup aria-label="Basic button group">
            {possibleField.map((f) => (
              <Button
                variant={
                  selectedField.indexOf(f.english) > -1
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleSelect(f.english)}
                key={f.english}
              >
                {englishVersion ? f.english : f.chinese}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Button
          variant="outlined"
          sx={{ verticalAlign: "bottom" }}
          onClick={handleSearch}
        >
          {englishVersion ? "Search" : "搜尋"}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "1em",
          textAlign: "end",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box display="flex">
          <Box display="flex" alignItems="center" sx={{ paddingRight: "2em" }}>
            <IconButton onClick={handleAnthologyFilterOpen}>
              <FilterListIcon />
            </IconButton>
            <Typography sx={{ fontSize: "0.875rem" }}>
              {englishVersion ? "Anthology" : "總集"}
            </Typography>
          </Box>
          <Menu
            anchorEl={anthologyAnchorEl}
            open={anthologyFilterOpen}
            onClose={handleAnthologyFilterClose}
          >
            <FormGroup sx={{ overflow: "auto", paddingLeft: "1em" }}>
              <FormControlLabel
                checked={Object.values(filterAnthology).every((t) => t)}
                control={<Checkbox />}
                label={englishVersion ? "All" : "全選"}
                key="all"
                onChange={() => handleAnthologyFilter("all")}
              />
              {Object.entries(anthologyKey).map((a) => (
                <FormControlLabel
                  checked={filterAnthology[a[0]]}
                  control={<Checkbox />}
                  label={a[1]}
                  key={a[0]}
                  onChange={() => handleAnthologyFilter(a[0])}
                />
              ))}
            </FormGroup>
          </Menu>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleDynastyFilterOpen}>
              <FilterListIcon />
            </IconButton>
            <Typography sx={{ fontSize: "0.875rem" }}>
              {englishVersion ? "Dynasty" : "朝代"}
            </Typography>
          </Box>
          <Menu
            anchorEl={dynastyAnchorEl}
            open={dynastyFilterOpen}
            onClose={handleDynastyFilterClose}
          >
            <FormGroup sx={{ overflow: "auto", paddingLeft: "1em" }}>
              <FormControlLabel
                checked={Object.values(filterDynasty).every((t) => t)}
                control={<Checkbox />}
                label={englishVersion ? "All" : "全選"}
                key="all"
                onChange={() => handleDynastyFilter("all")}
              />
              {dynastyList.data.sort().map((a) => (
                <FormControlLabel
                  checked={filterDynasty[a]}
                  control={<Checkbox />}
                  label={a}
                  key={a}
                  onChange={() => handleDynastyFilter(a)}
                />
              ))}
            </FormGroup>
          </Menu>
        </Box>
        <TablePagination
          component="div"
          count={filterPoem.length}
          page={page}
          showFirstButton={matches}
          showLastButton={matches}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Box sx={{ overflow: "auto", marginTop: "0.25em", marginBottom: "1em" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "600" }}>
                  {englishVersion ? "Title" : "詩題"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ width: { xs: "25%", md: "20%" }, fontWeight: "600" }}
                >
                  {englishVersion ? "Author" : "作者"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ width: { xs: "25%", md: "20%" }, fontWeight: "600" }}
                >
                  {englishVersion ? "Creation Year" : "成詩年代"}
                </TableCell>
                <TableCell align="right" sx={{ width: "4%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterPoem
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((p) => (
                  <PoemList
                    data={p}
                    handleClick={handleDialogOpen}
                    key={p.id}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PoemDetail
        onClose={handleDialogClose}
        open={openDialog}
        data={zoomInData}
        field={zoomInField}
        anthologyKey={anthologyKey}
        englishVersion={englishVersion}
      />
    </ContentContainer>
  );
};

export default Search;
