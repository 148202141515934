import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = (props) => {
  const { englishVersion } = props;

  // const pages = ["About", "Contact", "User Guide"];

  const pages = [
    { english: "About", chinese: "關於" },
    { english: "Contact", chinese: "聯絡我們" },
    { english: "User Guide", chinese: "使用指南" },
  ];

  const navigate = useNavigate();

  const handleNagivate = (page) => {
    const to = page === "User Guide" ? "guide" : page.toLowerCase();
    navigate(to);
  };

  const location = useLocation();

  return (
    <AppBar position="static" sx={{ bottom: 0 }}>
      <Toolbar
        sx={{
          backgroundColor: "#292c2f",
          height: "64px",
          minHeight: "64px",
          maxHeihgt: "64px",
          "@media (min-width:0px)": {
            "@media (orientation: landscape)": {
              minHeight: "64px",
              maxHeight: "64px",
            },
          },
          "@media (min-wdith:600px)": { minHeight: "64px", maxHeight: "64px" },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "flex" },
            alignItems: "center",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.english}
              onClick={() => handleNagivate(page.english)}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                paddingTop: "0px",
                paddingBottom: "0px",
                backgroundColor:
                  page.english.toLowerCase() ===
                  (location.pathname.split("/")[1] === "guide"
                    ? "user guide"
                    : location.pathname.split("/")[1])
                    ? "#ffffff40"
                    : "initial",
                "&:hover": { backgroundColor: "#ffffff80" },
              }}
            >
              {englishVersion ? page.english : page.chinese}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
