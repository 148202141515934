import { useQuery } from "@tanstack/react-query";
import AuthorNetwork from "./AuthorNetwork";
import { getAuthorRelationship } from "../function/getAuthorRelationship";
import { getAnthologyKey } from "../function/getAnthologyKey";
import ContentContainer from "../style/ContentContainer";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";

const AuthorRelationship = () => {
  const [searchText, setSearchText] = useState("陶淵明");
  const textValue = useRef();
  const divRef = useRef();

  const { data: relationshipData } = useQuery({
    queryKey: ["author", "relationship", searchText],
    queryFn: getAuthorRelationship(searchText),
  });

  const { data: anthologyKey } = useQuery({
    queryKey: ["anthology", "key"],
    queryFn: getAnthologyKey(),
  });

  const nodesData = [
    ...relationshipData.data,
    { id: searchText, name: searchText, type: "author" },
  ];

  const linksData = [];

  const anthologyCount = {};
  relationshipData.data.forEach((d) => {
    const anthology = d.title_source.split(/[;-]/);
    anthology.forEach((a) => {
      if (a in anthologyCount) {
        anthologyCount[a] += 1;
      } else {
        anthologyCount[a] = 1;
      }

      linksData.push({ source: d.id, target: a, value: 1 });
    });
  });

  Object.entries(anthologyCount).forEach(([k, v]) => {
    nodesData.push({
      id: k,
      name: anthologyKey[k],
      type: "anthology",
    });
    linksData.push({ source: searchText, target: k, value: v });
  });

  const handleClick = () => {
    setSearchText(textValue.current.value);
  };

  const [dimension, setDimension] = useState({ width: null, height: null });

  useEffect(() => {
    if (divRef.current) {
      setDimension({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItem: "flex-start",
        rowGap: "1em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: { xs: "100%", md: "20%" },
          height: "fit-content",
          alignItems: "center",
          columnGap: "1em",
          rowGap: "1em",
        }}
      >
        <TextField
          defaultValue={searchText}
          id="standard-basic"
          label="Author"
          variant="standard"
          inputRef={textValue}
        />
        <Button
          variant="outlined"
          sx={{
            verticalAlign: "bottom",
            color: "#a9714b",
            borderColor: "#a9714b",
            "&:hover": {
              backgroundColor: "#bd8c6a20",
              borderColor: "#a9714b",
            },
          }}
          onClick={handleClick}
        >
          Search
        </Button>
      </Box>
      <Box
        sx={{
          height: { xs: "60%", md: "100%" },
          width: { xs: "100%", md: "80%" },
        }}
        ref={divRef}
      >
        <AuthorNetwork
          data={{ nodes: nodesData, links: linksData }}
          dimension={dimension}
        />
      </Box>
    </ContentContainer>
  );
};

export default AuthorRelationship;
