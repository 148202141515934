import Box from "@mui/material/Box";
import ContentContainer from "../style/ContentContainer";

const Guide = () => {
  return (
    <ContentContainer>
      <Box>Guide</Box>
    </ContentContainer>
  );
};

export default Guide;
