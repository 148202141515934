import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AuthorNetwork = (props) => {
  const svgRef = useRef();
  const tooltipRef = useRef();
  const { data, dimension } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const edgeSize = Math.min(dimension.width, dimension.height);

  useEffect(() => {
    if (dimension.width) {
      const showTooltip = (event, d) => {
        if (d.source.type === "poem") return;
        tooltip.style("opacity", 1).style("display", "block");
        tooltip
          .html("Total selected poems: " + d.value)
          .style("left", event.x + 5 + "px")
          .style("top", event.y + 5 + "px");
      };

      const moveTooltip = (event, d) => {
        tooltip
          .style("left", event.x + 5 + "px")
          .style("top", event.y + 5 + "px");
      };

      const hideTooltip = (event, d) => {
        tooltip.style("opacity", 0).style("display", "none");
      };

      const ticked = () => {
        link
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)

          .attr("y2", (d) => d.target.y);

        node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

        text.attr("x", (d) => d.x + 6).attr("y", (d) => d.y - 6);
      };

      const dragstarted = (event, d) => {
        if (!event.active) simulation.alphaTarget(0.03).restart();
        d.fx = d.x;
        d.fy = d.y;
      };

      const dragged = (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      };

      const dragended = (event, d) => {
        if (!event.active) simulation.alphaTarget(0.03);
        d.fx = null;
        d.fy = null;
      };

      const fade = (opacity) => (event, d) => {
        if (d.type === "poem") {
          event.type === "mouseout"
            ? hideTooltip(event, d)
            : showTooltipPeom(event, d.name);
        }

        node.style("opacity", (o) => (isConnected(d, o) ? 1 : opacity));

        link.style("opacity", (o) =>
          o.source.name === d.name || o.target.name === d.name ? 1 : opacity
        );

        text.style("opacity", (o) => (isConnected(d, o) ? 1 : opacity));
      };

      const svg = d3.select(svgRef.current);
      const tooltip = d3.select(tooltipRef.current);
      svg.selectAll("*").remove();

      const margin = { top: 10, right: 30, bottom: 30, left: 40 },
        width = edgeSize - margin.left - margin.right,
        height = edgeSize - margin.top - margin.bottom;

      const networkSvg = svg
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .style("background", "#87810010")
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      tooltip
        .style("opacity", 0)
        .style("display", "none")
        .style("position", "absolute")
        .attr("class", "tooltip")
        .style("background-color", "black")
        .style("color", "white")
        .style("border-radius", "5px")
        .style("padding", "10px");

      const simulation = d3
        .forceSimulation()
        .nodes(data.nodes)
        .force(
          "link",
          d3
            .forceLink() // This force provides links between nodes
            .id((d) => d.id) // This provide  the id of a node
            .links(data.links) // and this the list of links
        )
        .force("charge", d3.forceManyBody().strength(-400)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
        .force("center", d3.forceCenter(width / 2, height / 2)) // This force attracts nodes to the center of the svg area
        .on("tick", ticked);

      const link = networkSvg
        .selectAll("line")
        .data(data.links)
        .join("line")
        .style("stroke", (d) =>
          d.source.type === "author" ? "#333333" : "#aaa"
        )
        .attr("stroke-width", (d) => Math.max(1, d.value / 10))
        .on("mouseover", showTooltip)
        .on("mousemove", moveTooltip)
        .on("mouseleave", hideTooltip);

      const node = networkSvg
        .selectAll("circle")
        .data(data.nodes)
        .join("circle")
        .attr("r", (d) =>
          d.type === "author" ? 15 : d.type === "anthology" ? 10 : 5
        )
        .style("fill", (d) =>
          d.type === "author"
            ? "#69b3a2"
            : d.type === "anthology"
            ? "#19a7ce"
            : "#f7db6a"
        )
        .on("mouseover", fade(0.2))
        .on("mousemove", moveTooltip)
        .on("mouseout", fade(1))
        .call(
          d3
            .drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended)
        );

      const text = networkSvg
        .selectAll("text")
        .data(data.nodes)
        .enter()
        .append("text")
        .text((d) => (d.type === "poem" ? "" : d.name));

      const linkedByIndex = {};
      data.links.forEach(
        (d) => (linkedByIndex[d.source.id + "," + d.target.id] = 1)
      );

      const isConnected = (a, b) =>
        linkedByIndex[a.id + "," + b.id] ||
        linkedByIndex[b.id + "," + a.id] ||
        a.id === b.id;

      const showTooltipPeom = (event, v) => {
        tooltip.style("display", "block").style("opacity", 1);
        tooltip
          .html(v)
          .style("left", event.x + 5 + "px")
          .style("top", event.y + 5 + "px");
      };
    }
  }, [props, data, dimension, edgeSize]);

  return (
    <>
      <svg
        ref={svgRef}
        viewBox={`${matches ? 0 : -edgeSize} ${matches ? 0 : -edgeSize} ${
          matches ? edgeSize : edgeSize * 3
        } ${matches ? edgeSize : edgeSize * 3}`}
      />
      <div ref={tooltipRef}></div>
    </>
  );
};

export default AuthorNetwork;
