import Box from "@mui/material/Box";
import FlipCover from "../image/flipcover.jpg";
import Typography from "@mui/material/Typography";

const AnthologyFlip = (props) => {
  const { data, handleClick, englishVersion } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "180px",
        height: "240px",
        background: "#e0e1dc",
        transformStyle: "preserve-3d",
        transform: "translate(-50%, -50%) perspective(2000px)",
        boxShadow:
          "inset 300px 0 50px rgba(0, 0, 0, 0.5), 20px 0 60px rgba(0, 0, 0, 0.5)",
        transition: "1s",
        cursor: "pointer",
        "&:before": {
          content: '""',
          position: "absolute",
          top: "-5px",
          left: "0",
          width: "100%",
          height: "5px",
          background: "#bac1ba",
          transformOrigin: "bottom",
          transform: "skewX(-45deg)",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          top: "0",
          right: "-5px",
          width: "5px",
          height: "100%",
          background: "#92a29c",
          transformOrigin: "left",
          transform: "skewY(-45deg)",
        },
        "&:hover": {
          transform:
            "translate(-50%, -50%) perspective(2000px) rotate(5deg) scale(1.2)",
          boxShadow:
            "inset 20px 0 50px rgba(0, 0, 0, 0.5), 0 10px 100px rgba(0, 0, 0, 0.5)",
        },
      }}
      onClick={() => handleClick(data.id)}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          transformOrigin: "left",
          transition: "1s",
          "&:hover": {
            transform: "rotateY(-135deg)",
            opacity: "0.3",
            transition: "1s",
            boxShadow: "300px 200px 100px rgba(0, 0, 0, 0.4) inset",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${FlipCover})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1em",
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: "1.25em" }}>
              {data.title}
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "1em" }}>
              {data.author}
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "1em" }}>
              {data.publish_year
                ? data.publish_year.split(";")[0]
                : data.creation_year
                ? data.creation_year.split(";")[0]
                : ""}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxSizing: "border-box",
          padding: "1em",
          zIndex: "-1",
          width: "100%",
        }}
      >
        <Typography>{englishVersion ? "Edition" : "版本"}</Typography>
        <Typography sx={{ paddingBottom: "1em" }}>{data.edition}</Typography>
        {/* {data.preface_list &&
          data.preface_list.split(",").map((p, i) => (
            <Typography
              sx={{ cursor: "pointer" }}
              key={`preface ${i + 1}`}
              // onClick={() => handleClick(data.id)}
            >{`preface ${i + 1}`}</Typography>
          ))} */}
      </Box>
    </Box>
  );
};

export default AnthologyFlip;
