import { useQuery } from "@tanstack/react-query";
import ContentContainer from "../style/ContentContainer";
import PoemCountBar from "./PoemCountBar";
import Box from "@mui/material/Box";
import { getAnthologyKey } from "../function/getAnthologyKey";
import { getPoemCount } from "../function/getPoemCount";
import { useEffect, useRef, useState } from "react";

const AnthologyData = () => {
  const divRef = useRef();

  const { data: anthologyKey } = useQuery({
    queryKey: ["anthology", "key"],
    queryFn: getAnthologyKey(),
  });

  const { data: poemCount } = useQuery({
    queryKey: ["poem", "count"],
    queryFn: getPoemCount(Object.keys(anthologyKey)),
  });

  const [dimension, setDimension] = useState({ width: null, height: null });

  useEffect(() => {
    if (divRef.current) {
      setDimension({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <ContentContainer
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        rowGap: "1em",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: { xs: "100%", md: "100%" },
        }}
        ref={divRef}
      >
        <PoemCountBar
          anthology={anthologyKey}
          data={poemCount}
          dimension={dimension}
        />
      </Box>
    </ContentContainer>
  );
};

export default AnthologyData;
