import ContentContainer from "../style/ContentContainer";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import { getAnthologyById } from "../function/getAnthologyById";
import Preface from "./Preface";

const AnthologyDetail = (props) => {
  const { id } = useParams();
  const { englishVersion } = props;

  const { data: anthologyData } = useQuery({
    queryKey: ["anthology", "data", id],
    queryFn: getAnthologyById(id),
  });

  const publishYearList = anthologyData.data[0].publish_year
    ? anthologyData.data[0].publish_year.split(";")
    : anthologyData.data[0].publish_year;

  const publishYearDynastyList = anthologyData.data[0].publish_year_dynasty
    ? anthologyData.data[0].publish_year_dynasty.split(";")
    : anthologyData.data[0].publish_year_dynasty;

  const creationYearList = anthologyData.data[0].creation_year
    ? anthologyData.data[0].creation_year.split(";")
    : anthologyData.data[0].creation_year;

  const creationYearDynastyList = anthologyData.data[0].creation_year_dynasty
    ? anthologyData.data[0].creation_year_dynasty.split(";")
    : anthologyData.data[0].creation_year_dynasty;

  return (
    <ContentContainer sx={{ overflow: "auto" }}>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">{anthologyData.data[0].title}</Typography>
          <Typography variant="h4">{anthologyData.data[0].author}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "start",
            paddingTop: "1em",
          }}
        >
          <Typography>{`版本：${anthologyData.data[0].edition}`}</Typography>
          {anthologyData.data[0].publish_year && (
            <Typography>
              {`出版年份：${publishYearList
                .map(
                  (y, i) =>
                    `${y}${
                      publishYearDynastyList
                        ? `（${publishYearDynastyList[i]}）`
                        : ""
                    }`
                )
                .join("至")}`}
            </Typography>
          )}
          {anthologyData.data[0].creation_year && (
            <Typography>
              {`成書年份：${creationYearList
                .map(
                  (y, i) =>
                    `${y}${
                      creationYearDynastyList
                        ? `（${creationYearDynastyList[i]}）`
                        : ""
                    }`
                )
                .join("至")}`}
            </Typography>
          )}
          {/* <Typography>{anthologyData.data[0].source}</Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {anthologyData.data[0].preface_list &&
          anthologyData.data[0].preface_list
            .split(",")
            .map((p) => <Preface id={p} key={p} />)}
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1em",
          textAlign: "start",
        }}
      >
        <Typography>
          {englishVersion
            ? "Disclaimer: The punctuated texts are generated and provided by the ChatGPT API. We are not responsible for any mistakes."
            : "聲明：加有現代標點的序言為 ChatGPT API 實時生成並提供，本站對任何標點或標註錯誤不負任何責任。"}
        </Typography>
      </Box>
    </ContentContainer>
  );
};

export default AnthologyDetail;
