import * as d3 from "d3";
import { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

const AnthologyCorrelogram = (props) => {
  const svgRef = useRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { anthlogyKey, totalData, similarityData, dataFilter, dimension } =
    props;

  const filterAnthology = Object.keys(anthlogyKey).filter((a) => dataFilter[a]);

  useEffect(() => {
    const edgeSize = Math.min(
      matches ? dimension.width * 0.6 : dimension.width,
      dimension.height
    );

    const usedData = Object.entries(similarityData)
      .filter(([k, v]) => {
        const [x, y] = k.split("_");
        return dataFilter[x] && dataFilter[y];
      })
      .map(([k, v]) => {
        const [x, y] = k.split("_");
        return { x: x, y: y, value: v };
      });

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 60, right: 60, bottom: 60, left: 60 };
    const chartWidth = edgeSize - margin.left - margin.right;
    const chartHeight = edgeSize - margin.top - margin.bottom;

    const chartSvg = svg
      .attr("width", chartWidth + margin.left + margin.right)
      .attr("height", chartHeight + margin.top + margin.bottom)
      .style("background", "#87810010")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const color = d3
      .scaleLinear()
      .domain([0, 0.5, 1])
      .range(["#B22222", "#000080"]); // "#fff",

    const x = d3.scalePoint().range([0, chartWidth]).domain(filterAnthology);
    const y = d3.scalePoint().range([0, chartHeight]).domain(filterAnthology);

    chartSvg
      .selectAll(".label")
      .data(filterAnthology)
      .join("g")
      .append("text")
      .attr("transform", (d) => `translate(${x(d)}, ${y(d)})`)
      .attr("y", 5)
      .text((d) => d)
      .style("font-size", 11)
      .style("font-weight", 600)
      .style("text-align", "center")
      .style("fill", "#000");

    const cor = chartSvg.selectAll(".cor").data(usedData).join("g");

    cor
      .append("text")
      .attr("transform", (d) => `translate(${x(d.x)}, ${y(d.y)})`)
      .attr("y", 5)
      .text((d) => `${((d.value / totalData[d.x]) * 100).toFixed(2)}%`)
      .style("font-size", 11)
      .style("text-align", "center")
      .style("fill", (d) => color(d.value / totalData[d.x]));

    cor
      .append("text")
      .attr("transform", (d) => `translate(${x(d.y)}, ${y(d.x)})`)
      .attr("y", 5)
      .text((d) => `${((d.value / totalData[d.y]) * 100).toFixed(2)}%`)
      .style("font-size", 11)
      .style("text-align", "center")
      .style("fill", (d) => color(d.value / totalData[d.y]));
  }, [
    props,
    anthlogyKey,
    totalData,
    similarityData,
    dataFilter,
    dimension,
    matches,
    filterAnthology,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        columnGap: "1.5em",
        rowGap: "1em",
      }}
    >
      <svg ref={svgRef} />
      <TableContainer
        component={Paper}
        sx={{
          height: "fit-content",
          width: { xs: dimension.width, md: dimension.width * 0.3 },
          backgroundColor: "#87810008",
        }}
      >
        <Table>
          <TableBody>
            {filterAnthology.map((a) => (
              <TableRow
                key={a}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {a}
                </TableCell>
                <TableCell align="right">{anthlogyKey[a]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnthologyCorrelogram;
