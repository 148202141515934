import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainLogo from "../image/mainicon.png";
import PortraitLogo from "../image/portrait.png";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const Header = (props) => {
  // const pages = ["Home", "Search", "Anthology", "Analytics"];
  const { englishVersion, handleLanguage } = props;

  const pages = [
    { english: "Home", chinese: "首頁" },
    { english: "Search", chinese: "搜尋" },
    { english: "Anthology", chinese: "總集" },
    { english: "Analytics", chinese: "數據分析" },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  // const [englishVersion, setEnglishVersion] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleNagivate = (page) => {
    const to = page === "Home" ? "/" : page.toLowerCase();
    navigate(to);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar
          sx={{
            backgroundColor: "#292c2f",
            height: "64px",
            minHeight: "64px",
            maxHeihgt: "64px",
            "@media (min-width:0px)": {
              "@media (orientation: landscape)": {
                minHeight: "64px",
                maxHeight: "64px",
              },
            },
            "@media (min-wdith:600px)": {
              minHeight: "64px",
              maxHeight: "64px",
            },
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box display={"flex"}>
            <img
              src={MainLogo}
              style={{ height: "40px", width: "auto" }}
              alt="logo"
            />
            <Typography
              variant="h5"
              sx={{
                paddingLeft: "1em",
                margin: "auto",
                fontWeight: 600,
                letterSpacing: ".3rem",
              }}
            >
              明清古詩總集
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={PortraitLogo}
              style={{ height: "40px", width: "auto" }}
              alt="portrait"
            />
            {pages.map((page) => (
              <Button
                key={page.english}
                onClick={() => handleNagivate(page.english)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  backgroundColor:
                    page.english.toLowerCase() ===
                    (location.pathname.split("/")[1]
                      ? location.pathname.split("/")[1]
                      : "home")
                      ? "#ffffff40"
                      : "initial",
                  "&:hover": { backgroundColor: "#ffffff80" },
                }}
              >
                {englishVersion ? page.english : page.chinese}
              </Button>
            ))}
            <Button
              onClick={() => handleLanguage()}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                paddingTop: "0px",
                paddingBottom: "0px",
                "&:hover": { backgroundColor: "#ffffff80" },
              }}
            >
              {englishVersion ? "中" : "EN"}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          backgroundColor: "#292c2f60",
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            backgroundColor: "#292c2f",
            boxSizing: "border-box",
            width: "50%",
          },
        }}
      >
        <Box
          onClick={handleDrawerToggle}
          sx={{ color: "#fff", textAlign: "center" }}
        >
          <List>
            {pages.map((page) => (
              <ListItem
                key={page.english}
                disablePadding
                onClick={() => handleNagivate(page.english)}
              >
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText
                    primary={englishVersion ? page.english : page.chinese}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
